@import "../variables/colour-variables";
.tabs-nav {
  position: relative;
  display: flex;
  flex-direction: row;
  top: 2px;
  button.btn-primary {
    border-left: none;
    min-width: 142px;
    border-right: none;
    border-top: none;
    border-bottom: 1px solid white;
    border-radius: 0;
    box-shadow: none;
    background-color: transparent;
    color: $body-color !important;
    &:hover {
      background-color: transparent;
      box-shadow: none;
    }
    &.btn:active {
      box-shadow: none;
      background: transparent;
      color: $body-color;
    }
    min-height: 34px;
    font-weight: $tab-btn-font-weight;
    line-height: $btn-line-height;
    padding: 0;
    &.tab-active {
      font-weight: $tab-btn-active-font-weight;
      background: transparent;
      color: $primary !important;
      border-bottom: 2px solid $primary;
    }
  }
}

.tabs {
  & .pill {
    border-radius: $border-radius;
    display: inline-block;
    font-weight: $tab-btn-font-weight;
    button,
    button.btn,
    button.btn-primary {
      background-color:$pill-bg;
      border-color:$pill-bg;
      border:1px solid $pill-bg;
      padding-left: $spacer*4;
      padding-right: $spacer*4;
      color:$pill-color;
      border-radius: $border-radius;
      &.tab-active {
        font-weight: $tab-btn-active-font-weight;
        background-color: $pill-active-bg;
        color: $pill-active-color;
      }
    }
  }
}

.tab-item {
  display: none;
  &.tab-active {
    display: block;
  }
}

.tab-icon {
  font-size: 1.4em;
  display: block;
}

.btn-tab {
  position: relative;
  .unread {
    position: absolute;
    right: 0;
    top: 9px;
    padding-left: 0 !important;
    padding-right: 0 !important;
    width: 16px;
    height: 16px;
  }
}
