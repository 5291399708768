.review-poa {
    input[type="checkbox"] {
      width: 20px;
      height: 20px;
    }
    &.reject-poa {
      .dropdown-menu {
        height: 180px;
      }
    }
    .img-shadow{
        box-shadow: 0px 3px 5px 0px rgba(0, 0, 0, 0.05), 0px 1px 18px 0px rgba(0, 0, 0, 0.03), 0px 6px 10px 0px rgba(0, 0, 0, 0.04);
    }
  }
