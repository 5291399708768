.progress-bar {
  height: 8px;
  >*{
    height: 8px !important;
    background: $light500 !important;
    >* {
      height: 8px !important;
      background-color: $primary !important;
    }
  }
}
