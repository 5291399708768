
.select {
  width: 100%;


  .select__menu {
    border: 1px solid $input-border-color !important;
    background-color: $dropdown-bg !important;
    box-shadow: $box-shadow !important;
    border-radius: $border-radius;
  }
  .select__input {
    color: $body-color !important;
  }
  .select__menu-list {
  }
  .select__single-value {
    color:$input-color !important;
  }
  .select__control--is-focused {
    border-color: $input-focus-border-color !important;
  }

  .select__option {
    background-color: transparent;
    &:hover {
      background-color: $dropdown-link-hover-bg;
    }
  }
  .select-form-control {
    position: relative;
    @extend .form-control;
    line-height: $input-line-height;
    background-color: $input-bg !important;
    height: $btn-line-height;
    box-shadow: none;
  }

  .select__value-container {
    padding: 0 30px 0 0 !important;

  }

  .select__input-container {
    margin: 0 !important;
    padding: 0 !important;

  }

  .select__indicators {
    .input-icon {
      right: 0px;
    }
  }
  .select__option--is-focused {}
  .select__option--is-selected {
    background-color: $dropdown-link-active-bg !important;
    color: $dropdown-link-active-color !important;
  }


  &--is-disabled {
    opacity: $input-disabled-opacity !important;
    .select-form-control {
      background-color: $input-disabled-bg !important;
    }
  }
}

.select__placeholder {
  color: $input-placeholder-color !important;
  margin: 0 !important;
}

.select-form-control {
  border-color: $input-border-color !important;
}

.select__indicator-separator {
  display: none !important;
}
.radius-0 {
  .select {
    .select__menu,.select__control {
      border-radius: 0 !important;
    }
  }
}
