@import 'bootstrap';
@import 'loqate';
@import 'nprogress';
@import 'fa';
@import 'hljs';
@import 'rcswitch';
@import 'react-select';
@import 'pdf';
@import 'json';
@import 'image-zoom';
@import 'phone-input';
@import 'progress-bar';
@import 'calendar';
@import 'tooltip';
@import '~react-pdf/dist/esm/Page/AnnotationLayer.css';
@import '~react-pdf/dist/esm/Page/TextLayer.css';
