@import "variables/index";
@import "styles/3rdParty/index";
@import "project/index";

.cursor-pointer, * .cursor-pointer {
  cursor: pointer;
}

.position-relative {
  position: relative;
}

.flex-row {
  display: flex;
}
.hidden-theme-light {
  display: none;
}
.hidden-theme-dark {
  display: block;
}
@include color-mode(dark) {
  .hidden-theme-light {
    display: block;
  }
  .hidden-theme-dark {
    display: none;
  }
}
code {
  text-wrap: wrap;
}

.flex-1 {
  display: flex;
  flex: 1;
}

.img-fluid {
  max-width: 100%;
}
mark {
  padding:0;
}
.pointer-events-none {
  pointer-events: none !important;
}
