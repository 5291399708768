.qr-modal {
  width: 400px;
  height: 300px;
  background-color: transparent;
  .modal-content {

    box-shadow: none;
    border: none;
  }
  .modal-body {
    background-color: black;
    border-radius: $border-radius;
    padding: 0;
    display: flex;
    flex-direction: column;
    justify-content: center;
    height: 300px;
  }
}
