.btn {
  text-wrap: nowrap;
  border-radius: $border-radius-lg;
  border-width: 2px;
  &.btn-primary {
    color: $btn-primary-color !important;
    &:hover {
      color: $btn-primary-color;
      background-color: $btn-primary-hover-background;
      border-color: $btn-primary-hover-background;
    }
    &:active,&:focus,&:focus-visible {
      background-color: $btn-primary-active-background;
      border-color: $btn-primary-active-background;
    }
  }
  &.btn-danger {
    color: white;
    &:hover {
      color: white;
    }
    &:active,&:focus,&:focus-visible {
      color: white;
    }
  }
  &.btn-secondary {
    color: $btn-secondary-color !important;
    border-color: $btn-secondary-border-color;
    box-shadow: $btn-secondary-shadow;
    background-color: $btn-secondary-background;
    &:hover {
      color: $btn-secondary-color;
      background-color: $btn-secondary-hover-background;
    }
    &:active,&:focus,&:focus-visible {
      background-color: $btn-secondary-active-background;
    }
  }
  line-height: $btn-line-height - 2px;
  height: $btn-line-height;
  &.btn-sm {
    line-height: $btn-line-height-sm - 4px;
    height: $btn-line-height-sm+2;
  }
  &.btn-xs {
    line-height: $btn-line-height-xs - 4px;
    height: $btn-line-height-xs+2;
  }
  &.btn-lg {
    padding-top: 0;
    padding-bottom: 0;
    line-height: $btn-line-height-lg - 4px;
    height: $btn-line-height-lg+2;
  }
  &.btn-outline-primary {
    &:hover {
      background-color: $primaryAlpha8;
    }
    &:active {
      background-color: $primaryAlpha16 !important;
      color:$primary !important;
    }
    &:hover, &:active {
      color: $primary;
    }
  }

  &.btn-outline-secondary {
    &:hover {
      background-color: $primaryAlpha8;
    }
    &:active {
      background-color: $primaryAlpha16 !important;
      color:$primary !important;
    }
    &:hover, &:active {
      color: $primary;
    }
  }

  &.btn-icon {
    padding: 0;
    min-width: $btn-line-height+2;
    display: flex;
    align-items: center;
    justify-content: center;
    &.btn-sm {
      min-width: $btn-line-height-sm+2;
    }
    &.btn-lg {
      min-width: $btn-line-height-lg+2;
    }
    &.btn-xs {
      min-width: $btn-line-height-xs+2;
    }
  }
}

.btn-pagination {
  font-size: $font-size-xs;
  font-weight: $font-weight-regular;
  border-radius: $border-radius !important;
  background: #fff;
  color: $body-color;
  display: flex;
  padding: $spacer !important;
  align-items: center;
  justify-content: center;
  min-width: 28px;
  height: 28px;
  &:disabled {
    opacity: 0.5;
  }
  &--active {
    background-color: $primary;
    color: $btn-primary-color;
    font-weight: $font-weight-semibold;
  }
}

.key__button {
  pointer-events: none;
  user-select: none;
  width: 15px;
  height: 15px;
  display: flex;
  align-items: center;
  justify-content: center;
  font-weight: 500;
  font-size: 11px;
  border-radius: 2px;
  margin-left: 8px;
  line-height: 1;
  background: #FFF;
  color: #000;
  box-shadow: 0px 1px 1px 1px rgba(0, 0, 0, 0.25);
  font-style: normal !important;
}

.btn-lg {
  .key__button {
    padding-bottom: 2px;
    width: 18px;
    height: 18px;
    font-size: 14px;
    box-shadow: 0px 2px 2px 2px rgba(0, 0, 0, 0.25);

  }
}

.shortcut-absolute {
  position: relative;
  justify-content: center;
  padding-right: 20px;
  .key__button {
    position: absolute;
    right: 8px;
  }
}
