//PROJECT STYLES
@import "buttons";
@import "forms";
@import "badge";
@import "aside";
@import "chip";
@import "dropdown";
@import "type";
@import "modals";
@import "destinations";
@import "nav";
@import "qr-modal";
@import "toast";
@import "shadow";
@import "tabs";
@import "custom";
.body-bg {
  background-color: $body-bg;
}
.text-overflow-ellipsis {
  text-overflow: ellipsis;
}
.disabled > * {
  opacity: 0.25;
  cursor: not-allowed !important;
}
.right-0 {
  right: 0 !important;
}
.border-1 {
  border-width: 1px;
  border-style: solid;
}
.border-2 {
  border-width: 2px;
  border-style: solid;
}
.left-0 {
  left:0;
}
.line-height-lg {
  line-height: 22px;
}

svg {
  @each $name, $color in $colors {
    &.text-#{$name} {
      path, circle, rect {
        fill: #{$color};
      }

      &:hover {
        path, circle, rect {
          fill: #{$color};
        }
      }
    }

    &.stroke-#{$name} {
      path, circle, rect {
        stroke: #{$color};
      }

      &:hover {
        path, circle, rect {
          stroke: #{$color};
        }
      }
    }
  }
}

path, circle, rect {
  @each $name, $color in $colors {
    &.text-#{$name} {
      fill: #{$color};

      &:hover {
        fill: #{$color};
      }
    }
    &.bg-#{$name} {
      fill: #{$color};
    }

    &.stroke-#{$name} {
      stroke: #{$color};

      &:hover {
        stroke: #{$color};
      }
    }
  }
}
