.react-pdf__Document {
  z-index: 0;
}
.pdf-controls {
  position: absolute;
  text-align: right;
  top: 0px;
  right: 40px;
  z-index: 99999999;
}
