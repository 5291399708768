
//Scaffolding
$breakpoints: (
        mobile-small: 320px,
        mobile: 375px,
        handheld-device: 576px,
        tablet: 768px,
        tablet-large: 992px,
        desktop: 1200px
);



$spacer: 4px;
$spacers: (
        0: 0,
        1: $spacer * 1,
        2: $spacer * 2,
        3: $spacer * 3,
        4: $spacer * 4,
        5: $spacer * 5,
        6: $spacer * 6,
        7: $spacer * 7,
        8: $spacer * 8,
        9: $spacer * 9,
        10: $spacer * 10,
        11: $spacer * 11,
        12: $spacer * 12,
        13: $spacer * 13,
        14: $spacer * 14,
        15: $spacer * 15,
        16: $spacer * 16,
        17: $spacer * 17,
        18: $spacer * 18,
        19: $spacer * 19,
        20: $spacer * 20,
        21: $spacer * 21,
        22: $spacer * 22,
        23: $spacer * 23,
        24: $spacer * 24,
        25: $spacer * 25,
        26: $spacer * 26,
        27: $spacer * 27,
        28: $spacer * 28,
        29: $spacer * 29,
        30: $spacer * 30,
        31: $spacer * 31,
        32: $spacer * 32,
        33: $spacer * 33,
        34: $spacer * 34,
        35: $spacer * 35,
        36: $spacer * 36,
        37: $spacer * 37,
        38: $spacer * 38,
        39: $spacer * 39,
        40: $spacer * 40
);

