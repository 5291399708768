@import "../mixins/custom-scrollbar";
$aside-width:300px;

.aside {
  .dropdown {
    background-color: $aside-bg;
  }
  overflow-y: auto;
  background-color: $aside-bg;
  width: $aside-width;
  @include customScroll();
  &__section {
    background-color: $aside-section-bg;
  }
  &__item {
    .fa {
      opacity: 0.4;
      width: 20px;
      display: inline-block;
      text-align: center;
    }
    user-select: none;
    color:$aside-text-inactive;
    &:hover {
      background-color: $aside-item-hover-bg;
      color: $aside-item-hover-color;
      .fa {
        opacity: 1;
      }
    }
    &--active {
      cursor: default;
      background-color: $aside-item-active !important;
      color:$aside-text !important;
      .fa {
        opacity: 0.4;
      }
    }
  }
  .active .aside__item {
    @extend .aside__item--active;
  }
}
.project-search {
  width: 265px;
}
.aside-fixed {
  position: fixed;
  top: 0;
  left: 0;
  bottom:0;
}
.aside-offset {
  margin-left: $aside-width;
}
