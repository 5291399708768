
.dropdown-item {
  display: flex;
  flex-direction: row;
  align-items: center;
  line-height: 28px;
  &:first-of-type {
    border-top-left-radius: $border-radius;
    border-top-right-radius: $border-radius;
  }
  &:last-of-type {
    border-bottom-left-radius: $border-radius;
    border-bottom-right-radius: $border-radius;
    border-bottom: none !important;
  }
  &:hover {
    background-color: $dropdown-link-hover-bg;
  }
  &:active {
    background-color: $dropdown-link-active-bg;
    color: $body-color;
  }
  &__icon {
    display: inline-block;
    color: $body-secondary-color;
    font-size: $font-size-xs;
  }
  &--danger {
    color: $danger !important;
    &:hover {
      color: $danger !important;
      background-color: $dropdown-item-danger-active-bg !important;
    }
  }
}

.dropdown-menu.right {
  right: 0;
}


.dropdown-menu.auto {
  min-width: auto;
}
