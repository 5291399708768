.badge {
  font-weight: 600;
  padding: $spacer/2 $spacer*3;
  background-color:$badge-bg;
  color:$badge-color;
  border-radius: $border-radius-sm;
  display: flex;
  line-height: normal;
  align-items: center;
  &-secondary {
    background-color: $light400 !important;

  }
  &--counter {
    height: 20px;
    min-width: 20px;
    width: fit-content;
    border-radius: 10px;
    padding:1px 0 0 0!important;
    display: flex;
    justify-content: center;
    align-items: center;
    font-weight: $font-weight-semibold;
  }
  &--dot {
    height: 12px;
    min-width: 12px;
    padding:0;
    border-radius: 6px;
  }
}
.btn {
  .badge--counter {
    position: absolute;
    top: -5px;
    right: -5px;
  }
}
