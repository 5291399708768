@import "../mixins/custom-scrollbar";
$code-bg: $dark300;
$invalid: $danger;
$code-color: $light500;
$literal-color: $info;
$class-color: #a6e22e;
$var-color: #64c77c;
$emphasis-color: $light300;
$text-color: $light500;
$keyword-color: $warning700;
$attr-color: $light500;
$type-color: $info;
$code-font-size: $font-size-base;
.invalid .value-editor .hljs {
  border: 2px solid $danger;
}
.hljs {
  text-shadow: none !important;
  padding-left: $spacer*4;
  padding-right: $spacer*4;
  padding-bottom: $spacer*4;
  a {
    color: white !important;
    text-decoration: underline;
  }
  .hljs-comment {
    color: $text-muted !important;
  }
  cursor: text;
  outline: none;
  font-size: $code-font-size;
  display: block;
  overflow-x: auto;
  background: $code-bg;
  border-radius: $border-radius;
}

.hljs-attr {
  color: $attr-color;
}

.yaml {
  .hljs-bullet {
    color: $text-color;
  }

  .hljs-string {
    color: $literal-color;
  }
}

.hljs {
  color: $code-color;
}

.hljs-strong,
.hljs-emphasis {
  color: $emphasis-color;
}

.hljs-bullet,
.hljs-quote,
.hljs-link,
.hljs-number,
.hljs-regexp,
.hljs-literal {
  color: $emphasis-color;
}

.hljs-code,
.hljs-selector-class {
  color: $class-color;
}

.hljs-emphasis {
  font-style: italic;
}
.hljs-keyword,
.hljs-selector-tag,
.hljs-section,
.hljs-name,
.hljs-variable {
  color: $keyword-color;
}

.hljs-params {
  color: $text-color;
}

.hljs-string {
  color: $success;
}

.hljs-subst,
.hljs-type,
.hljs-built_in,
.hljs-builtin-name,
.hljs-symbol,
.hljs-selector-id,
.hljs-selector-attr,
.hljs-selector-pseudo,
.hljs-template-tag,
.hljs-template-variable,
.hljs-addition {
  color: $type-color;
}

.hljs-comment,
.hljs-deletion,
.hljs-meta {
  color: $text-muted;
}
.value-editor pre {
  overflow: hidden;
}
.value-editor pre code {
  height: 100%;
}


pre {
  cursor: default !important;
  @include customScrollDark();
}
