body, html {
  color: $body-color;
  -webkit-font-smoothing: antialiased;
}

.text-muted {
  color: $text-muted !important;
}
.text-body,a.text-body {
  color: $body-color !important;
}
p {
  font-size: $fsP;
  color: $body-secondary-color;
}
.body-secondary {
  color: $body-secondary-color;
}
.text-link {
  color: $primary;
  &:hover {
    color: $btn-primary-hover-background;
  }
}
.text-bg-dark {
  color: $body-color-dark;
}
.hover-color-primary {
  &:hover {
    color: $primary;
  }
}
