.invalid input {
  border-color: $danger;
}
textarea {
  line-height: normal !important;
  min-height: 80px !important;
  padding-top: $spacer !important;
  padding-bottom: $spacer !important;
}
.input-container {
  .input-icon {
    position: absolute;
    height: $btn-line-height;
    line-height: $btn-line-height;
    width: 22px;
    top: 0;
    display: flex;
    align-items: center;
  }
  .icon-right {
    right:10px;
    justify-content: flex-end;
  }
  .icon-left {
    left:10px;
  }
  &--with-left-icon {
    .form-control {
      padding-left: 30px;
    }
  }
  &--with-right-icon {
    .form-control {
      padding-right: 30px;
    }
  }
}
.form-control {
  line-height: $input-line-height;
  &:disabled {
    opacity: $input-disabled-opacity !important;
  }
}
.icon-disabled {
  opacity: $input-disabled-opacity !important;
}
.lh-input {
  line-height: $input-line-height;
}

.form-check-input:focus {
  background-color: $form-check-input-focus-bg-color;
}

.form-check-input[type="checkbox"] {
  &:checked {
    border: none !important;
    &:focus,&:active{
      background-color: $form-check-input-checked-focus-bg-color;
      filter: none;
    }
  }
}


.form-check-input[type="radio"] {
  &:checked {
    border: none !important;
    background-color: $form-check-radio-checked-bg-color;
  }
}

.form-check-label.disabled {
  opacity: $input-disabled-opacity;
}

table {
  border: 1px solid $hr-border-color;
  border-collapse: separate;
  border-spacing: 0;
  border-radius: $border-radius;
}
.table-group {
  color: $body-color;
  font-weight: $font-weight-bold;
  padding-left: $spacer*4;
  border-bottom: none !important;
}

td {
  padding: $td-padding;
  vertical-align: middle;
}
th {
  padding: $th-padding;
  background-color: $th-bg;
  border-bottom: 1px solid $hr-border-color;
}
tbody {
  color: $body-secondary-color;
}
tbody tr {
  vertical-align: baseline;
  td {
    border-bottom: 1px solid $hr-border-color;
  }
  &:last-of-type {
    td {
      border-bottom: 1px solid transparent;
    }
  }
  //border-left: 1px solid $hr-border-color !important;
  //border-right: 1px solid $hr-border-color !important;
}
thead {
  //border-top: 1px solid $hr-border-color !important;
  //border-left: 1px solid $hr-border-color !important;
  //border-right: 1px solid $hr-border-color !important;
  font-size: $th-font-size;
  th:first-of-type {
    border-top-left-radius: $border-radius;
  }
}
.input-icon {
  color: $input-icon-color;
  * {color: $input-icon-color;}
  path {
    fill: $input-icon-color;
  }
}

.invalid {
  .form-control {
    border-color: $danger !important;
  }
}

.outline {
  border: 1px solid $hr-border-color;
}

input:-webkit-autofill,
input:-webkit-autofill:hover,
input:-webkit-autofill:focus,
textarea:-webkit-autofill,
textarea:-webkit-autofill:hover,
textarea:-webkit-autofill:focus,
select:-webkit-autofill,
select:-webkit-autofill:hover,
select:-webkit-autofill:focus {
 background-color: $primaryAlpha8 !important;
  -webkit-box-shadow: 0 0 0px 1000px $input-bg-autofill inset;

  transition: background-color 5000s ease-in-out 0s;

}

.logo-fill {
  fill:$body-color;
}
td.fitwidth {
    width: 1px;
    white-space: nowrap;
}

.autocomplete {
  min-width: 100%;
  max-height: 400px;
  overflow-y: auto;
}

.hover-show:hover {
  .d-hover-flex {
    display: flex !important;
  }
}

.mh-100 {
  min-height: 100%;
}
.radius-0 {
  border-radius: 0 !important;
}

.radius-top-0 {
  border-top-left-radius: 0 !important;
  border-top-right-radius: 0 !important;
}

.radius-bottom-0 {
  border-bottom-left-radius: 0 !important;
  border-bottom-right-radius: 0 !important;
}

.border-top-0 {
  border-top: 0 !important;
}

.border-bottom-0 {
  border-bottom: 0 !important;
}

.border-left-0 {
  border-left: 0 !important;
}
.border-right-0 {
  border-right: 0 !important;
}
