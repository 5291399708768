@import "~react-calendar/dist/Calendar.css";

.react-calendar__navigation__next2-button, .react-calendar__navigation__prev2-button {
  display: none !important;
}

.react-calendar {
  padding: 0 $spacer*2 $spacer*2 $spacer*2;
  border: none;
  width: 300px;
  margin:auto;
  
  &.react-calendar--doubleView {
    width: 600px;
  }
  color: $body-color;
  background-color: $body-bg;
  abbr[title] {
    cursor: default;
    text-decoration: none;
  }
  button, .react-calendar__month-view__days__day--weekend {
   background-color: $body-bg !important;
    padding: $spacer;
    border-radius: 0 !important;
    justify-content: center;
    display: flex;
    align-items: center;
    &.react-calendar__tile--now {
      >* {
        color: $btn-primary-active-background;
        font-weight: $font-weight-semibold;
      }
    }
    &.react-calendar__tile--hasActive,&.react-calendar__tile--active {
      >* {
        background-color: $btn-primary-active-background !important;
        color: $btn-primary-color;
      }
    }

    >* {
      align-items: center;
      justify-content: center;
      display: flex;
      border-radius: $border-radius;
      height: $btn-line-height-sm;
      color: $body-color;
      user-select: none;
    }
    &.react-calendar__tile--range {
      background-color: $primaryAlpha16 !important;
      &.react-calendar__tile--rangeBothEnds {
        background-color: $body-bg !important;
      }
      >* {
        background-color: transparent !important;
        color: $body-color !important;
      }
      &.react-calendar__tile--rangeStart {

        border-top-left-radius: $border-radius !important;
      }
      &.react-calendar__tile--rangeEnd {
        border-bottom-right-radius: $border-radius !important;
      }
      &.react-calendar__tile--rangeStart, &.react-calendar__tile--rangeEnd {
        >* {
          background-color: $btn-primary-active-background !important;
          color: $btn-primary-color !important;
        }
      }
    }
    &:hover,&:focus {
      >* {
        background-color: $primaryAlpha8 !important;
      }
    }
  }
  .react-calendar__year-view__months__month, .react-calendar__decade-view__years__year {
    padding: 0;
  }
  .react-calendar__navigation {
    padding-top: 0;
    padding-bottom: 0;
    margin-bottom: $spacer;
  }
  .react-calendar__month-view__days__day--neighboringMonth {
    color: $text-muted;
  }

  .react-calendar__tile--hover {
    background-color: $primaryAlpha8 !important;
  }
  .react-calendar__tile--hover {
    background-color: $primaryAlpha8 !important;
  }

  .react-calendar__month-view__days__day >* {
    height: $btn-line-height-xs !important;
    width: $btn-line-height-xs !important;
  }

  .react-calendar__month-view__days__day--neighboringMonth {
    visibility: hidden;
  }
}
