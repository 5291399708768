
$light100: white;
$light200: #F9FAFA;
$light300: #F7F7F7;
$light400: #EFEFEF;
$light500: #DBDBDB;
$light600: #A7ACBD;
$light700: #7A8093;
$light800: #969696;
$light900: #585757;

$dark100: #202020;
$dark200: #1C1C1C;
$dark300: #1C1C1C;
$dark400: #1C1C1C;
$dark500: #1C1C1C;
$dark600: #1C1C1C;

$primary: #B839A9;
$primaryAlpha8: rgba($primary,0.08);
$primaryAlpha16: rgba($primary,0.16);
$primary400: #c55fb8;
$primary600: #aa009b;
$primary700: #9c0096;
$primary800: #8b008f;
$primary900: #7b0088;


$secondary: #1D1D1F;
$secondary400: #1D1D1F;
$secondary700: #1D1D1F;
$secondary900: #1D1D1F;
$secondaryAlpha8: rgba($secondary,0.08);
$secondaryAlpha16: rgba($secondary,0.16);

$warning: #E49A43;
$warning400: #E8A650;
$warning700: #DF8F3F;
$warning900: #D8813A;

$info: #55A2E1;
$info400: #DDECF9;
$info700: #1e5abd;
$info900: #003e9b;

$success: #4CB67E;
$success400: #67C191;
$success700: #3D9466;
$success900: #2F6245;

$danger: #E15555;
$danger400: #d87375;
$danger700: #d63d3c;
$danger900: #b72f2f;
$dropdown-item-danger-active-bg: rgba($danger,0.08);


$hr-border-color: $light400;
$hr-opacity: 1;

$link-color: $primary600;
$link-hover-color: $primary700;
$link-color-dark: $primary700;
$link-hover-color-dark: $primary800;

//Products
$collect: #8530F7;
$collect400: #9D59F9;
$forward: #FF4F7B;
$forward400: #FF7295;
$scan: #3EB8E5;
$scan400: #65C6EA;
$scan_pro: #B82AA9;
$scan_pro400: #dd3ccb;
$scan400: #65C6EA;
$parcel: #e5893e;
$parcel400: #ea9665;

@import "_color-variables-overrides";
// Then add them to your custom theme-colors map, together with any additional colors you might need
$colors: (
        card: $body-bg,
        primary: $primary,
        primaryAlpha8: $primaryAlpha8,
        primaryAlpha16: $primaryAlpha16,
        primary400: $primary400,
        primary600: $primary600,
        primary700: $primary700,
        primary800: $primary800,
        primary900: $primary900,
        secondary: $secondary,
        secondary400: $secondary400,
        secondary700: $secondary700,
        secondary900: $secondary900,
        secondaryAlpha8: $secondaryAlpha8,
        secondaryAlpha16: $secondaryAlpha16,
        info: $info,
        info400: $info400,
        info700: $info700,
        info900: $info900,
        warning: $warning,
        warning400: $warning400,
        warning700: $warning700,
        warning900: $warning900,
        success: $success,
        success400: $success400,
        success700: $success700,
        success900: $success900,
        danger: $danger,
        danger400: $danger400,
        danger700: $danger700,
        danger900: $danger900,
        light: $light500,
        light100: $light100,
        light200: $light200,
        light300: $light300,
        light400: $light400,
        light600: $light600,
        light700: $light700,
        light800: $light800,
        light900: $light900,
        dark: $dark500,
        dark100: $dark100,
        dark200: $dark200,
        dark300: $dark300,
        dark400: $dark400,
        dark600: $dark600,
        collect: $collect,
        collect400: $collect400,
        forward: $forward,
        forward400: $forward400,
        scan: $scan,
        scan_pro: $scan_pro,
        scan400: $scan400,
        parcel: $parcel,
        parcel400: $parcel400,
);

$shadow-1: 0px 1px 1px 0px rgba(0, 0, 0, 0.04), 0px 2px 1px 0px rgba(0, 0, 0, 0.03), 0px 1px 3px 0px rgba(0, 0, 0, 0.05);
$shadow-3: 0px 3px 4px 0px rgba(0, 0, 0, 0.04), 0px 3px 3px 0px rgba(0, 0, 0, 0.03), 0px 1px 8px 0px rgba(0, 0, 0, 0.05);
$shadow-6: 0px 6px 10px 0px rgba(0, 0, 0, 0.04), 0px 1px 18px 0px rgba(0, 0, 0, 0.03), 0px 3px 5px 0px rgba(0, 0, 0, 0.05);
$shadow-12: 0px 12px 17px 0px rgba(0, 0, 0, 0.04), 0px 5px 22px 0px rgba(0, 0, 0, 0.03), 0px 7px 8px 0px rgba(0, 0, 0, 0.05);
$shadow-24: 0px 12px 17px 0px rgba(0, 0, 0, 0.04), 0px 5px 22px 0px rgba(0, 0, 0, 0.03), 0px 7px 8px 0px rgba(0, 0, 0, 0.05);


$theme-colors: $colors;
$body-secondary-color: $light900;
$text-muted: $light800;
$body-color: $dark500;
$body-color-dark: $light300;

$btn-primary-color: white;
$btn-primary-hover-background: $primary600;
$btn-primary-active-background: $primary700;
$btn-secondary-color: $body-color;
$btn-secondary-border-color: $secondary;
$btn-secondary-shadow: $shadow-1;
$btn-secondary-background: $light100;
$btn-secondary-hover-background: $light200;
$btn-secondary-active-background: $light300;

$switch-bg: $primary;
$switch-bg-off: $light500;

$form-check-input-bg: $light100;
$form-check-input-border-color: $input-border-color;
$form-check-input-checked-border-color: $primary;
$form-check-focus-border-color: $primary;
$form-check-input-checked-bg-color:$primary;
$form-check-input-focus-border:$primary;
$form-check-input-focus-bg-color:$primaryAlpha16;
$form-check-input-checked-focus-bg-color:$primary400;
$form-check-input-focus-box-shadow: none;
$form-check-radio-checked-bg-color: $primary;


$form-check-input-checked-bg-image: url('data:image/svg+xml,<svg xmlns="http://www.w3.org/2000/svg" width="20" height="20" viewBox="0 0 20 20" fill="none"><path fill-rule="evenodd" clip-rule="evenodd" d="M13.75 6C13.405 6 13.0925 6.14 12.8663 6.36625L11.6163 7.61625L8.75 10.4825L7.13375 8.86625C6.9075 8.64 6.595 8.5 6.25 8.5C5.56 8.5 5 9.06 5 9.75C5 10.095 5.14 10.4075 5.36625 10.6337L7.86625 13.1337C8.0925 13.36 8.405 13.5 8.75 13.5C9.095 13.5 9.4075 13.36 9.63375 13.1337L13.3837 9.38375L14.6337 8.13375C14.86 7.9075 15 7.595 15 7.25C15 6.55937 14.44 6 13.75 6Z" fill="white"/></svg>');
$form-check-radio-checked-bg-image: url('data:image/svg+xml,<svg xmlns="http://www.w3.org/2000/svg" width="20" height="20" viewBox="0 0 20 20" fill="none"><path fill-rule="evenodd" clip-rule="evenodd" d="M10 14C12.2091 14 14 12.2091 14 10C14 7.79086 12.2091 6 10 6C7.79086 6 6 7.79086 6 10C6 12.2091 7.79086 14 10 14Z" fill="white"/></svg>');

$input-placeholder-color: $light600;
$input-border-color: $light500;
$input-focus-border-color: $primary;
$input-bg-autofill: $primaryAlpha8;
$dropdown-link-hover-bg: $primaryAlpha8;
$dropdown-link-active-bg: $primaryAlpha16;
$dropdown-link-active-color: $primary;

$th-bg: $light300;

$border-color: $light500;
$input-icon-color: $light600;

$aside-bg: $light300;
$aside-section-bg: $light300;

$aside-item-active: $primary;
$aside-text:$light100;
$aside-text-inactive:$dark200;
$aside-item-hover-bg:$primaryAlpha8;
$aside-item-hover-color:$primary;

$tooltip-bg: $dark500;
$tooltip-text: white;
$input-bg: $light200;
$dropdown-bg: $body-bg;
$pill-bg: transparent;
$pill-color: $body-color;
$pill-active-bg: $primaryAlpha8;
$pill-active-color: $primary;

$chip-color: $light700;
$chip-bg: $light400;
$chip-hover-bg: $light400;

$badge-color:$light700;
$badge-bg:$light200;
$badge-hover-bg: $light300;


$navbar-light-color: $body-color;
$navbar-light-active-color: $primary;
$navbar-light-hover-color: $primary400;

$dropdown-link-active-color: inherit;

@each $name, $hex in $theme-colors {
  .hover-bg-#{$name}:hover{ //workaround for lack of text-Theme support in dark mode
    background-color: $hex !important;
    &.bg-opacity-10 {
      background-color: rgba($hex,0.1) !important;
    }
  }
}
