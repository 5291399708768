@import "../variables/form_variables";
.rc-switch {
  position: relative;
  display: inline-block;
  box-sizing: border-box;
  width: $switch-width;
  height: $switch-height;
  line-height: 20px;
  padding: 0;
  vertical-align: middle;
  border-radius: 20px 20px;
  border: 1px solid $switch-bg-off;
  background-color: $switch-bg-off;
  cursor: pointer;
  transition: all 0.3s cubic-bezier(0.35, 0, 0.25, 1);
}
.rc-switch-inner {
  color: #fff;
  font-size: 12px;
  position: absolute;
  left: 24px;
  top: 0;
}
.rc-switch:after {
  position: absolute;
  width: $switch-height - 6px;
  height: $switch-height - 6px;
  left: 2px;
  top: 2px;
  border-radius: 50% 50%;
  background-color: #fff;
  content: " ";
  cursor: pointer;
  transform: scale(1);
  transition: left 0.3s cubic-bezier(0.35, 0, 0.25, 1);
  animation-timing-function: cubic-bezier(0.35, 0, 0.25, 1);
  animation-duration: 0.3s;
  animation-name: rcSwitchOff;
}
.rc-switch:hover:after {
  transform: scale(1.1);
  animation-name: rcSwitchOn;
}
.rc-switch:focus {
  outline: none;
}
.rc-switch-checked {
  border: 1px solid $switch-bg;
  background-color: $switch-bg;
}
.rc-switch-checked .rc-switch-inner {
  left: 6px;
}
.rc-switch-checked:after {
  left: auto;
  right:2px;
}
.rc-switch-disabled {
  cursor: no-drop;
  opacity: $switch-disabled-opacity;
}
.rc-switch-disabled:after {
  animation-name: none;
  cursor: no-drop;
}
.rc-switch-disabled:hover:after {
  transform: scale(1);
  animation-name: none;
}

@keyframes rcSwitchOn {
  0% {
    transform: scale(1);
  }
  50% {
    transform: scale(1.25);
  }
  100% {
    transform: scale(1.1);
  }
}
@keyframes rcSwitchOff {
  0% {
    transform: scale(1.1);
  }
  100% {
    transform: scale(1);
  }
}
