
$btn-padding-y:0;
$btn-padding-x:$spacer*4;

$btn-padding-y-sm: 0;
$btn-padding-x-sm:$spacer*4;
$btn-line-height: 38px;
$btn-line-height-sm: 28px;
$btn-line-height-xs: 28px;
$btn-line-height-lg: 48px;

$line-height-base: 1.37;
$input-line-height: $btn-line-height - 2px;
$input-padding-y:0;
$input-focus-box-shadow: none;

$form-label-font-weight: $font-weight-semibold;

$form-check-input-width: 20px;
$form-check-input-height: 20px;

$btn-disabled-opacity: 0.5;
$input-disabled-opacity: 0.5;

$switch-width: 40px;
$switch-height: 20px;

$td-padding: 8px 12px;
$th-padding: 6px 12px;

$dropdown-item-padding-x: $spacer*3;

$switch-disabled-opacity:$input-disabled-opacity;
$form-check-input-disabled-opacity: $input-disabled-opacity;
$form-check-label-disabled-opacity: $input-disabled-opacity;

$link-decoration: none;
$link-hover-decoration: none;
$link-font-weight: $font-weight-semibold;
a {
  font-weight:$link-font-weight;
}


@include color-mode(dark) {
  $input-disabled-opacity: 0.25;
  $switch-disabled-opacity:$input-disabled-opacity;
  $form-check-input-disabled-opacity: $input-disabled-opacity;
  $form-check-label-disabled-opacity: $input-disabled-opacity;
}


$tab-btn-font-weight: $font-weight-semibold;
$tab-btn-active-font-weight: $font-weight-semibold;

$nav-item-height: 54px;

$modal-content-border-radius: $border-radius;
