@import "~bootstrap/scss/functions";
@import "~bootstrap/scss/mixins";
@import "~bootstrap/scss/variables";
@import "~bootstrap/scss/variables-dark";

$border-radius-xl: 42px;
$border-radius-lg: 42px;
$border-radius: 8px;
$border-radius-sm: 8px;

@import "grid_variables";
@import "font-variables";
@import "form_variables";
@import "colour-variables";
