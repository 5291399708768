.destination-logo {
  height:22px;
  svg, img {
    height: 100%;
    width: auto;
  }
  &--small {
    height: 14px;
    line-height: 14px;
    .logo,.logo--small{font-size: 14px !important;}
    svg, img {
      height: 100%;
      width: auto;
    }
  }
}
